import { Backdrop, Button, CircularProgress, Grid, Paper, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import GenericDeleteDialog from "../../components/Dialogs/GenericDeleteDialog";
import AddInstallationDialog from "../../components/Dialogs/Installations/AddInstallationDialog";
import InstallationDetails from "../../components/Installations/InstallationDetails";
import InstallationsList from "../../components/Installations/InstallationsList";
import InstallationsMap from "../../components/Installations/InstallationsMap";
import { useAddInstallationMutation, useDeleteInstallationMutation, useGetAllInstallationsQuery, useUpdateInstallationLocationMutation } from "../../features/api/apiSlice";
import { selectCurrentUser } from "../../features/auth/authSlice";
import { selectCurrentInstallation, setSelectedInstallation } from "../../features/installations/installationsSlice";
import { IInstallation } from "../../models/IInstallation";
import { UserRole } from "../../models/IUser";

export default function MapPage() {
    const navigate = useNavigate();
    const authUser = useAppSelector(selectCurrentUser);
    const selectedInstallation = useAppSelector(selectCurrentInstallation);
    const dispatcher = useAppDispatch();

    const { data: installations, isLoading } = useGetAllInstallationsQuery();

    const [editMode, setEditMode] = useState(false);

    const [updateInstallationLocation] = useUpdateInstallationLocationMutation();

    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

    const [addInstallation, addInstallationResult] = useAddInstallationMutation();
    const [deleteInstallation, deleteInstallationResult] = useDeleteInstallationMutation();

    const handleAddInstallation = (name: string, description: string | null, latitude: number, longitude: number, ownerId?: string | null) => {
        setOpenAddDialog(false);
        addInstallation({ name, description, latitude, longitude, ownerId: ownerId ? ownerId : authUser.id });
    }

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        dispatcher(setSelectedInstallation(null));
    };

    const handleDeleteInstallation = (installation: IInstallation) => {
        setOpenDeleteDialog(false);
        deleteInstallation({installationId: installation.id, deleteInstallation: {ownerId: installation.ownerId}});
    };

    const handleGoToInstallation = (installation: IInstallation) => {
        dispatcher(setSelectedInstallation(installation.id));
        navigate("/installations");
    };

    return (
        <>
            <Grid container display="flex" rowSpacing={1} direction="row" alignItems="center" justifyContent="center" width='100%' sx={{ mt: 1 }}>
                <Grid item xs={12} width="100%" display="flex" sx={{ justifyContent:"end", mr: 1 }} >
                    {authUser.role !== UserRole.User &&
                        <Button variant="contained" onClick={() => setOpenAddDialog(true)}>Añadir Instalación</Button>
                    }
                </Grid>

                <Grid item xs={12} sx={{ m: 1 }}>
                    <Stack direction="row" spacing={1}>
                        <Stack direction="column" width='100%' height='80vh' spacing={1}>
                            <Paper elevation={4} sx={{ width: '100%', height: '70%' }} square>
                                <Stack direction="column" width='100%' height='100%' padding={2} spacing={1}>
                                    <Typography variant="h5" fontWeight="bold">Mapa {editMode ? `(Editando localización de ${selectedInstallation.name})` : ""}</Typography>
                                    <InstallationsMap
                                        installations={installations}
                                        selectedInstallation={selectedInstallation}
                                        onSelectedInstallation={(installation) => dispatcher(setSelectedInstallation(installation.id))}
                                        editMode={editMode}
                                        onCancelEdit={() => setEditMode(false)}
                                        onConfirmEdit={
                                            (latitude, longitude) => {
                                                setEditMode(false);
                                                updateInstallationLocation({
                                                    installationId: selectedInstallation.id,
                                                    updateInstallationLocation: {
                                                        ownerId: selectedInstallation.ownerId,
                                                        latitude: latitude,
                                                        longitude: longitude
                                                    }
                                                });
                                            }
                                        }
                                    />
                                </Stack>
                            </Paper>
                            
                            <Paper elevation={4} sx={{ width: '100%', height: '30%'}} square>
                                <Stack direction="column" width='100%' height='100%' padding={2} spacing={1}>
                                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                                        <Typography variant="h5" fontWeight="bold">Detalles</Typography>
                                        {selectedInstallation && <Button variant="contained" onClick={() => { handleGoToInstallation(selectedInstallation) }}>Más detalles</Button>}
                                    </Stack>
                                    <InstallationDetails installation={selectedInstallation} onEditLocationTap={() => setEditMode(true)} />
                                </Stack>
                            </Paper>
                        </Stack>

                        <Paper elevation={4} sx={{ width: '30%', height: '80vh'}} square={true}>
                            <Stack direction="column" width='100%' height='100%' padding={2} spacing={1}>
                                <Typography variant="h5" fontWeight="bold">Listado</Typography>
                                <InstallationsList
                                    installations={installations}
                                    selectedInstallation={selectedInstallation}
                                    onSelectedInstallation={(installation) => { dispatcher(setSelectedInstallation(installation.id)); }}
                                    onGoToInstallationClick={(installation) => { handleGoToInstallation(installation) }}
                                    onDeleteInstallationClick={(installation) => { dispatcher(setSelectedInstallation(installation.id)); setOpenDeleteDialog(true); }}
                                    isLoading={isLoading}
                                />
                            </Stack>
                        </Paper>
                    </Stack>
                </Grid>
            </Grid >

            {openAddDialog && <AddInstallationDialog openDialog={openAddDialog} handleClose={() => setOpenAddDialog(false)} handleAddInstallation={handleAddInstallation} />}
            
            {openDeleteDialog &&
                <GenericDeleteDialog
                    openDialog={openDeleteDialog}
                    handleClose={handleCloseDeleteDialog}
                    title={`Quieres eliminar ${selectedInstallation.name} con Id ${selectedInstallation.id} ?`}
                    message="Está acción no es reversible"
                    handleDelete={() => handleDeleteInstallation(selectedInstallation)}
                />
            }

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={addInstallationResult.isLoading || deleteInstallationResult.isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { IComponent } from "../../../models/IComponent";

export type IUpdateComponentDialogProps = {
    openDialog: boolean;
    handleClose: () => void;
    component: IComponent;
    handleUpdateComponent: (componentId: string, name: string) => void;
};

export default function UpdateDeviceDialog({ openDialog, handleClose, component, handleUpdateComponent }: IUpdateComponentDialogProps) {
    const { handleSubmit, control } = useForm();

    function onSubmit(data: any) {
        handleUpdateComponent(component.id, data.name);
        handleClose();
    }

    const handleUpdateDialogClose: (event: any, reason: any) => void = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }

        handleClose();
    };

    return (
        <Dialog
            open={openDialog}
            onClose={handleUpdateDialogClose}
        >
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>Edit Device</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Change the following fields to update the device
                    </DialogContentText>

                    <Controller
                        name="name"
                        control={control}
                        defaultValue={component.name}
                        rules={{
                            required: "Campo Requerido"
                        }}
                        render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                            <TextField
                                margin="normal"
                                label="Nombre"
                                type="text"
                                fullWidth
                                variant="outlined"
                                required
                                value={value}
                                onChange={onChange}
                                error={invalid}
                                helperText={error ? error.message : ""}
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleUpdateDialogClose(null, null)} variant="outlined">Cancelar</Button>
                    <Button type="submit" variant="outlined">Actualizar</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
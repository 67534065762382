import Map, { MapRef, Marker, MarkerDragEvent, NavigationControl } from 'react-map-gl';
import { IInstallation } from '../../models/IInstallation';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Stack } from '@mui/material';

import 'mapbox-gl/dist/mapbox-gl.css';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

type InstallationsMapProps = {
    installations?: IInstallation[],
    selectedInstallation?: IInstallation,
    onSelectedInstallation: (installation: IInstallation) => void,
    editMode: boolean,
    onCancelEdit: () => void,
    onConfirmEdit: (latitude: number, longitude: number) => void
}

const InstallationsMap = ({ installations, selectedInstallation, onSelectedInstallation, editMode, onCancelEdit, onConfirmEdit }: InstallationsMapProps) => {
    const mapRef = useRef<MapRef>();

    const [editCoordinates, setEditCoordinates] = useState({ lng: 0, lat: 0 });
    const onMarkerDrag = useCallback((event: MarkerDragEvent) => {
        setEditCoordinates({
            lng: event.lngLat.lng,
            lat: event.lngLat.lat
        });
    }, []);

    useEffect(() => {
        if (mapRef.current && selectedInstallation?.longitude && selectedInstallation?.latitude) {
            mapRef.current.flyTo({ center: [selectedInstallation.longitude, selectedInstallation.latitude] })
        }
    }, [selectedInstallation]);

    useEffect(() => {
        if (editMode) {
            setEditCoordinates({ lng: selectedInstallation?.longitude ?? 0, lat: selectedInstallation?.latitude ?? 0 });
        }
    }, [selectedInstallation, editMode]);

    return (
        <>
            <Map
                initialViewState={{
                    longitude: 2,
                    latitude: 42,
                    zoom: 3.5
                }}
                ref={mapRef}
                style={{ width: '100%', height: '100%' }}
                mapStyle="mapbox://styles/mapbox/streets-v11"
                onLoad={() => {
                    if (mapRef.current && selectedInstallation?.longitude && selectedInstallation?.latitude) {
                        mapRef.current.flyTo({ center: [selectedInstallation.longitude, selectedInstallation.latitude] })
                    }
                }}
            >
                {editMode &&
                    <Marker
                        latitude={editCoordinates.lat}
                        longitude={editCoordinates.lng}
                        draggable
                        onDrag={onMarkerDrag}
                        color="green"
                    />
                }

                {!editMode && installations?.filter(x => x.id !== selectedInstallation?.id).map(installation =>
                    <Marker
                        key={installation.id}
                        latitude={installation.latitude}
                        longitude={installation.longitude}
                        onClick={() => {onSelectedInstallation(installation)}}
                        color="#0a416e"
                    />
                )}
                {!editMode && selectedInstallation &&
                    <Marker
                        latitude={selectedInstallation.latitude}
                        longitude={selectedInstallation.longitude}
                        onClick={() => {onSelectedInstallation(selectedInstallation)}}
                        color="#5c6fb8"
                    />
                }
                <NavigationControl showCompass={false} />
            </Map>
            {editMode &&
                <Stack direction="row-reverse" spacing={2}>
                    <Button variant="contained" onClick={() => onConfirmEdit(editCoordinates.lat, editCoordinates.lng)}>Set</Button>
                    <Button variant="contained" color="error" onClick={onCancelEdit}>Cancel</Button>
                </Stack>
            }
        </>
    )
}

export default InstallationsMap;
import { Box, Stack } from "@mui/material";
import { FunctionComponent } from "react";
import TopAppBar from "../../components/TopAppBar/TopAppBar";

const Layout: FunctionComponent<any> = ({ children }) => {
  return (
    <Box justifyContent="center" sx={{ display: 'flex', width: '100%', minHeight: '100vh', backgroundColor: '#c8d9f2'}}>
      <Stack width="100%">
        <TopAppBar />
        <Box component="main" >
            {children}
        </Box>
      </Stack>
    </Box>
  );
}

export default Layout;
import { Button, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { IComponent } from "../../../models/IComponent";
import EditDiagramView from "./EditDiagramView";
import { IInstallation } from "../../../models/IInstallation";
import DiagramView from "./DiagramView";

export type IDiagramTabProps = {
    selectedInstallation: IInstallation;
    components?: IComponent[];
    showActions: boolean;
}

export default function DiagramTab({ selectedInstallation, components, showActions }: IDiagramTabProps) {
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        setEditMode(false);
    }, [selectedInstallation])

    return (
        <Grid container display="flex" rowSpacing={1} direction="row" alignItems="center" justifyContent="center" width='100%' >
            {!editMode && selectedInstallation.diagram?.imageUrl &&
                <Grid item display="flex" justifyContent="end" xs={12} sx={{ mr: 1 }}>
                    {showActions &&
                        <Button variant="contained" onClick={() => setEditMode(true)}>Editar Diagrama</Button>
                    }
                </Grid>
            }
            <Grid item md={12} sx={{ mx: 1 }}>
                {editMode ?
                    <EditDiagramView
                        selectedInstallation={selectedInstallation}
                        components={components}
                        onEditCompleted={() => setEditMode(false)}
                    />
                    :
                    selectedInstallation.diagram?.imageUrl ?
                        <DiagramView
                            selectedInstallation={selectedInstallation}
                            components={components}
                        />
                        :
                        <Grid item display="flex" xs={12} minHeight="70vh" alignItems="center" justifyContent="center">
                            <Stack direction="column" spacing={1} alignItems="center">
                                <Typography variant="h6">
                                    Todavía no has configurado el diagrama
                                </Typography>
                                <Button variant="contained" onClick={() => setEditMode(true)} sx={{width: '150px'}}>Configurar</Button>
                            </Stack>
                        </Grid>
                }
            </Grid>
        </Grid >
    );
}
export interface IUpdateInstallationDiagramImageRequest {
    ownerId: string | null,
    image: File;
}

export const toFormData: (request: IUpdateInstallationDiagramImageRequest) => FormData = (request) => {
    const formData = new FormData();
    
    formData.append("image", request.image);

    if (request.ownerId !== null && request.ownerId !== undefined) {
        formData.append("ownerId", request.ownerId)
    }

    return formData;
};
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button, ListSubheader, Typography, MenuItem, FormControl, InputLabel, Select, Stack, Menu, SelectChangeEvent } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { IDiagramNode } from "../../../models/IDiagramNode";
import { IComponent } from "../../../models/IComponent";
import { IDataInput } from "../../../models/IDataInput";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";
import { ReactNode, useState } from "react";
import { isSingle, validateExpression } from "../../../helpers/ExpressionHelper";

export type IEditNodeDialogProps = {
    components: IComponent[],
    node: IDiagramNode,
    openDialog: boolean;
    handleClose: () => void;
    handleEditNode: (node: IDiagramNode) => void;
};

export default function EditNodeDialog({ components, node, openDialog, handleClose, handleEditNode }: IEditNodeDialogProps) {
    const { handleSubmit, watch, setValue, getValues, control } = useForm();
    const watchNodeType = watch("nodeType", isSingle(node.expression) ? "single" : "expression");

    function getUnitsForComponentInput(componentId: string, dataInputId: string): string {
        return components
            .find(c => c.id === componentId)?.dataInputs
            .find(di => di.id === dataInputId)?.units ?? "";
    }

    function getSingleSelectItems(): ReactNode[] {
        const nodes = new Array<ReactNode>();
        components.forEach(component => {
            nodes.push(<ListSubheader key={component.id}><Typography fontWeight="bold">{component.name}</Typography></ListSubheader>);

            component.dataInputs.forEach(dataInput => {
                nodes.push(<MenuItem key={`${component.id}:${dataInput.id}`} value={`{${component.id}:${dataInput.id}}`} disableRipple>{dataInput.name}</MenuItem>);
            });
        });

        return nodes;
    }

    function getExpressionSelectItems(onClick: (component: IComponent, dataInputId: IDataInput) => void): ReactNode[] {
        const nodes = new Array<ReactNode>();

        components.forEach(component => {
            nodes.push(<ListSubheader key={component.id}><Typography fontWeight="bold">{component.name}</Typography></ListSubheader>);

            component.dataInputs.forEach(dataInput => {
                nodes.push(
                    <MenuItem
                        key={`${component.id}:${dataInput.id}`}
                        value={`${component.id}:${dataInput.id}`}
                        onClick={() => onClick(component, dataInput)}
                        disableRipple
                    >
                        {dataInput.name}
                    </MenuItem>
                );
            });
        });

        return nodes;
    }

    function onSubmit(data: any) {
        switch (data.nodeType) {
            case 'single':
                handleEditNode({ ...node, name: data.name, expression: data.single, units: data.units });
                break;
            case 'expression':
                handleEditNode({ ...node, name: data.name, expression: data.expression, units: data.units });
                break;
            default:
                return;
        }

        handleClose();
    }

    const handleAddDialogClose: (event: any, reason: any) => void = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }

        handleClose();
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleAddComponentClose = () => {
        setAnchorEl(null);
    };

    const handleAddComponentClick: (event: any) => void = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <Dialog
            open={openDialog}
            onClose={handleClose}
        >
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>Editar Nodo</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Actualiza los siguientes campos
                    </DialogContentText>

                    <Controller
                        name="name"
                        control={control}
                        defaultValue={node.name}
                        rules={{
                            required: "Campo requerido"
                        }}
                        render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                            <TextField
                                margin="normal"
                                label="Nombre"
                                type="text"
                                fullWidth
                                variant="outlined"
                                required
                                value={value}
                                onChange={onChange}
                                error={invalid}
                                helperText={error ? error.message : ""}
                            />
                        )}
                    />

                    <Controller
                        name="nodeType"
                        control={control}
                        defaultValue={isSingle(node.expression) ? "single" : "expression"}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <FormControl fullWidth margin="normal" sx={{ minWidth: 150 }}>
                                <InputLabel id="node-type-select-label">Tipo Entrada</InputLabel>
                                <Select
                                    labelId="node-type-select-label"
                                    label="Tipo Entrada"
                                    required
                                    value={value}
                                    onChange={(event: SelectChangeEvent<any>, child: React.ReactNode) => {
                                        onChange(event, child);

                                        if (getValues("nodeType") === "expression") {
                                            setValue("units", isSingle(node.expression) ? "" : node.units)
                                        } else {
                                            const selectedSingleInput = getValues("single");
                                            if (selectedSingleInput === null || selectedSingleInput === undefined || selectedSingleInput === "") {
                                                setValue("units", "");
                                            } else {
                                                setValue("units", getUnitsForComponentInput(selectedSingleInput.split(":")[0].replaceAll("{", ""), selectedSingleInput.split(":")[1].replaceAll("}", "")));
                                            }
                                        }
                                    }}
                                >
                                    <MenuItem value="single">Simple</MenuItem>
                                    <MenuItem value="expression">Expresión</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    />

                    {watchNodeType === "single" &&
                        <Controller
                            name="single"
                            control={control}
                            defaultValue={isSingle(node.expression) ? node.expression : ""}
                            render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                                <FormControl fullWidth margin="normal" sx={{ minWidth: 150 }}>
                                    <InputLabel id="data-input-select-label">Componente</InputLabel>
                                    <Select
                                        labelId="data-input-select-label"
                                        label="Dato Entrada"
                                        required
                                        value={value}
                                        onChange={(event: SelectChangeEvent<any>, child: React.ReactNode) => {
                                            onChange(event, child);
                                            const selectedSingleInput = getValues("single");
                                            setValue("units", selectedSingleInput ? getUnitsForComponentInput(selectedSingleInput.split(":")[0].replaceAll("{", ""), selectedSingleInput.split(":")[1].replaceAll("}", "")) : "");
                                        }}
                                    >
                                        {
                                            getSingleSelectItems()
                                        }
                                    </Select>
                                </FormControl>
                            )}
                        />
                    }

                    {watchNodeType === "expression" &&
                        <>
                            <FormControl fullWidth margin="normal" sx={{minWidth: 150}}>
                                <Stack direction="row" spacing={1} maxWidth="600px">
                                    <Button variant="contained" onClick={handleAddComponentClick} endIcon={<KeyboardArrowDownIcon />}>Componente</Button>
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                    >
                                        {getExpressionSelectItems(
                                            (component, dataInput) => {
                                                handleAddComponentClose();
                                                setValue("expression", getValues("expression") + `{${component.id}:${dataInput.id}}`);
                                            })
                                        }
                                    </Menu>
                                </Stack>
                            </FormControl>
                            <Controller
                                name="expression"
                                control={control}
                                rules={{
                                    validate: (value: string, _formValues: any) => (value !== undefined && value !== null && value !== "" && validateExpression(value, components)) || "Expresión inválida"
                                }}
                                defaultValue={node.expression}
                                render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                                    <TextField
                                        margin="normal"
                                        label="Expresión"
                                        type="text"
                                        fullWidth
                                        multiline
                                        variant="outlined"
                                        value={value}
                                        onChange={onChange}
                                        error={invalid}
                                        helperText={error ? error.message : ""}
                                    />
                                )}
                            />
                        </>
                    }

                    <Controller
                        name="units"
                        control={control}
                        defaultValue={node.units}
                        rules={{
                            required: "Campo requerido"
                        }}
                        render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                            <TextField
                                margin="normal"
                                label="Unidades"
                                type="text"
                                fullWidth
                                required
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={invalid}
                                helperText={error ? error.message : ""}
                                disabled={watchNodeType === "single"}
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleAddDialogClose(null, null)} variant="outlined">Cancelar</Button>
                    <Button type="submit" variant="outlined">Actualizar</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
import { Box, Grid, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { IComponent } from "../../../models/IComponent";
import { IDiagramNode } from "../../../models/IDiagramNode";
import { IInstallation } from "../../../models/IInstallation";
import ChartPanel from "./ChartPanel";
import DiagramPanel from "./DiagramPanel";

export type IDiagramViewProps = {
    selectedInstallation: IInstallation;
    components: IComponent[];
}

export default function DiagramView({ selectedInstallation, components }: IDiagramViewProps) {
    const [selectedNodes, setSelectedNodes] = useState<IDiagramNode[]>([]);

    useEffect(() => {
        setSelectedNodes([]);
    }, [selectedInstallation]);

    const handleOnSelectedNode = (node: IDiagramNode) => {
        const index = selectedNodes.findIndex(x => x.id === node.id);
        if (index === -1) {
            setSelectedNodes([...selectedNodes, node])
        } else {
            setSelectedNodes([
                ...selectedNodes.slice(0, index),
                ...selectedNodes.slice(index + 1)
            ]);
        }
    };

    return (
        <Grid container width='100%'>
            <Grid item xs={6} paddingRight={1} mb={1}>
                <Paper elevation={4} sx={{ width: '100%', height: '100%', minHeight: "75vh" }} square>
                    <Box component="div" width='100%' height='100%'>
                        <DiagramPanel
                            components={components}
                            diagram={selectedInstallation.diagram}
                            selectedNodes={selectedNodes}
                            onSelectedNode={handleOnSelectedNode}
                        />
                    </Box>
                </Paper>
            </Grid>

            <Grid item xs={6}>
                <Paper elevation={4} sx={{position:"sticky", top:'1rem', display: 'flex', width: '100%' }} square>
                    <ChartPanel
                        selectedInstallationId={selectedInstallation.id}
                        selectedNodes={selectedNodes}
                    />
                </Paper>
            </Grid>
        </Grid>
    )
}
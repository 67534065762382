import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import { apiSlice } from '../api/apiSlice';

type InstallationsState = {
  selectedInstallationId: string | null;
}

const initialState: InstallationsState = {
    selectedInstallationId: null
}

const installationsSlice = createSlice({
  name: 'installations',
  initialState: initialState,
  reducers: {
    setSelectedInstallation: (state, { payload }: PayloadAction<string>
    ) => {
        state.selectedInstallationId = payload;
    }
  }
})

export const selectAllInstallations = createSelector(
  apiSlice.endpoints.getAllInstallations.select(),
  installationsResult => installationsResult.data ?? []
)

export const selectCurrentInstallation = createSelector(
  selectAllInstallations,
  (state: RootState) => state.installations.selectedInstallationId,
  (installations, installationId) => installations.find(installation => installation.id === installationId)
)

export const { setSelectedInstallation } = installationsSlice.actions

export default installationsSlice.reducer

//export const selectCurrentInstallation = (state: RootState) => state.installations.selectedInstallation

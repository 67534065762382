import { createApi } from '@reduxjs/toolkit/query/react'
import { IDataInputEntry } from '../../models/IDataInputEntry'
import { IInstallation } from '../../models/IInstallation'
import { IUser } from '../../models/IUser'
import { ILoginRequest } from '../../models/requests/authentication/ILoginRequest'
import { IRefreshTokenRequest } from '../../models/requests/authentication/IRefreshTokenRequest'
import { IAddComponentRequest } from '../../models/requests/components/IAddComponentRequest'
import { IDeleteComponentRequest } from '../../models/requests/components/IDeleteComponentRequest'
import { IUpdateComponentRequest } from '../../models/requests/components/IUpdateComponentRequest'
import { IAddInstallationRequest } from '../../models/requests/installations/IAddInstallationRequest'
import { IDeleteInstallationRequest } from '../../models/requests/installations/IDeleteInstallationRequest'
import { IUpdateInstallationDiagramImageRequest, toFormData } from '../../models/requests/installations/IUpdateInstallationDiagramImageRequest'
import { IUpdateInstallationDiagramNodesRequest } from '../../models/requests/installations/IUpdateInstallationDiagramNodesRequest'
import { IUpdateInstallationLocationRequest } from '../../models/requests/installations/IUpdateInstallationLocationRequest'
import { IUpdateInstallationRequest } from '../../models/requests/installations/IUpdateInstallationRequest'
import { ISignalRSubscribeAllRequest } from '../../models/requests/signalr/ISignalRSubscribeAllRequest'
import { ISignalRSubscribeRequest } from '../../models/requests/signalr/ISignalRSubscribeRequest'
import { ISignalRUnsubscribeRequest } from '../../models/requests/signalr/ISignalRUnsubscribeRequest'
import { IAddUserRequest } from '../../models/requests/users/IAddUsersRequest'
import { IUpdateUserRequest } from '../../models/requests/users/IUpdateUserRequest'
import { ILoginResponse } from '../../models/responses/authentication/ILoginResponse'
import { IRefreshTokenResponse } from '../../models/responses/authentication/IRefreshTokenResponse'
import { IAddComponentResponse } from '../../models/responses/components/IAddComponentResponse'
import { IGetInstallationComponentsResponse } from '../../models/responses/components/IGetInstallationComponentsResponse'
import { IGetHistoricDataResponse } from '../../models/responses/data/IGetHistoricDataResponse'
import { IGetPredictionDataResponse } from '../../models/responses/data/IGetPredictionDataResponse'
import { IAddInstallationResponse } from '../../models/responses/installations/IAddInstallationResponse'
import { IGetAllInstallationsResponse } from '../../models/responses/installations/IGetAllInstallationsResponse'
import { IUpdateInstallationDiagramImageResponse } from '../../models/responses/installations/IUpdateInstallationDiagramImageResponse'
import { IAddUserResponse } from '../../models/responses/users/IAddUserResponse'
import { IGetAllUsersResponse } from '../../models/responses/users/IGetAllUsersResponse'
import { baseQueryWithReauth } from './baseQueryWithReauth'

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Installation', 'Component', 'User'],
  endpoints: builder => ({
    // Auth
    login: builder.mutation<ILoginResponse, ILoginRequest>({
        query: requestData => ({
            url: '/auth/login',
            method: "POST",
            body: requestData
        })
    }),
    refreshToken: builder.mutation<IRefreshTokenResponse, IRefreshTokenRequest>({
        query: requestData => ({
            url: '/auth/refreshtoken',
            method: "POST",
            body: requestData
        })
    }),
    logout: builder.mutation<void, void>({
        query: () => ({
            url: '/auth/logout',
            method: "POST"
        })
    }),
    // Users
    addUser: builder.mutation<IAddUserResponse, IAddUserRequest>({
        query: requestData => ({
            url: '/users',
            method: 'POST',
            body: requestData
        }),
        invalidatesTags: ['User'],
    }),
    updateUser: builder.mutation<void, {userId: string, updateUser: IUpdateUserRequest}>({
        query: requestData => ({
            url: `/users/${requestData.userId}`,
            method: 'PUT',
            body: requestData.updateUser
        }),
        invalidatesTags: ['User'],
    }),
    getAllUsers: builder.query<IUser[], void>({
        query: () => '/users',
        transformResponse: (response: IGetAllUsersResponse, meta, arg) => response.data,
        providesTags: (result, error, arg) =>
        result
          ? [...result.map(({ id }) => ({ type: 'User' as const, id })), 'User']
          : ['User'],
    }),
    deleteUser: builder.mutation<void, string>({
        query: userId => ({
            url: `/users/${userId}`,
            method: 'DELETE'
        }),
        invalidatesTags: ['User'],
    }),
    // Installations
    addInstallation: builder.mutation<IAddInstallationResponse, IAddInstallationRequest>({
        query: requestData => ({
            url: '/installations',
            method: "POST",
            body: requestData
        }),
        invalidatesTags: ['Installation']
    }),
    updateInstallation: builder.mutation<void, { installationId: string, updateInstallation: IUpdateInstallationRequest }>({
        query: requestData => ({
            url: `/installations/${requestData.installationId}`,
            method: "PUT",
            body: requestData.updateInstallation
        }),
        invalidatesTags: ['Installation']
    }),
    updateInstallationLocation: builder.mutation<void, {installationId: string, updateInstallationLocation: IUpdateInstallationLocationRequest }>({
        query: requestData => ({
            url: `/installations/${requestData.installationId}/location`,
            method: "PUT",
            body: requestData.updateInstallationLocation
        }),
        invalidatesTags: ['Installation']
    }),
    updateInstallationDiagramImage: builder.mutation<IUpdateInstallationDiagramImageResponse, {installationId: string, updateInstallationDiagramImage: IUpdateInstallationDiagramImageRequest }>({
        query: requestData => ({
            url: `/installations/${requestData.installationId}/diagram/image`,
            method: "PUT",
            body: toFormData(requestData.updateInstallationDiagramImage),
        }),
        invalidatesTags: ['Installation']
    }),
    updateInstallationDiagramNodes: builder.mutation<void, {installationId: string, updateInstallationDiagramNodes: IUpdateInstallationDiagramNodesRequest }>({
        query: requestData => ({
            url: `/installations/${requestData.installationId}/diagram/nodes`,
            method: "PUT",
            body: requestData.updateInstallationDiagramNodes
        }),
        invalidatesTags: ['Installation']
    }),
    getAllInstallations: builder.query<IInstallation[], void>({
        query: () => '/installations',
        transformResponse: (response: IGetAllInstallationsResponse, meta, arg) => response.data,
        providesTags: (result, error, arg) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Installation' as const, id })), 'Installation']
          : ['Installation']
    }),
    deleteInstallation: builder.mutation<void, { installationId: string, deleteInstallation: IDeleteInstallationRequest }>({
        query: requestData => ({
            method: "DELETE",
            url: `/installations/${requestData.installationId}`,
            body: requestData.deleteInstallation
        }),
        invalidatesTags: ['Installation']
    }),
    // Components
    addComponent: builder.mutation<IAddComponentResponse, IAddComponentRequest>({
        query: requestData => ({
            url: '/components',
            method: "POST",
            body: requestData
        }),
        invalidatesTags: ['Component']
    }),
    getInstallationComponents: builder.query({
        query: (installationId) => `/installations/${installationId}/components`,
        transformResponse: (response: IGetInstallationComponentsResponse, meta, arg) => response.data,
        providesTags: (result, error, arg) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Component' as const, id })), 'Component']
          : ['Component']
    }),
    updateComponent: builder.mutation<void, { componentId: string, updateComponent: IUpdateComponentRequest }>({
        query: requestData => ({
            url: `/components/${requestData.componentId}`,
            method: "PUT",
            body: requestData.updateComponent
        }),
        invalidatesTags: ['Component']
    }),
    deleteComponent: builder.mutation<void, { componentId: string, deleteComponent: IDeleteComponentRequest }>({
        query: requestData => ({
            url: `/components/${requestData.componentId}`,
            method: "DELETE",
            body: requestData
        }),
        invalidatesTags: ['Component']
    }),
    // Data
    getHistoricData: builder.query<IDataInputEntry[], { installationId: string, componentId: string, dataId: string, fromDate?: number | null, toDate?: number | null }>({
        query: requestData => {
            const { fromDate, toDate } = requestData;
            return {
                url: `/installations/${requestData.installationId}/components/${requestData.componentId}/${requestData.dataId}/history`,
                params: {fromDate, toDate}
            }
        },
        transformResponse: (response: IGetHistoricDataResponse, meta, arg) => response.data
    }),
    getPredictionData: builder.query<IDataInputEntry[], { installationId: string, componentId: string, dataId: string, fromDate?: number | null, toDate?: number | null }>({
        query: requestData => {
            const { fromDate, toDate } = requestData;
            return {
                url: `/installations/${requestData.installationId}/components/${requestData.componentId}/${requestData.dataId}/prediction`,
                params: {fromDate, toDate}
            }
        },
        transformResponse: (response: IGetPredictionDataResponse, meta, arg) => response.data
    }),
    // SignalR
    subscribe: builder.mutation<void, ISignalRSubscribeRequest>({
        query: requestData => ({
            url: '/signalr/subscribe',
            method: "POST",
            body: requestData
        })
    }),
    subscribeAll: builder.mutation<void, ISignalRSubscribeAllRequest>({
        query: requestData => ({
            url: '/signalr/subscribeall',
            method: "POST",
            body: requestData
        })
    }),
    unsubscribe: builder.mutation<void, ISignalRUnsubscribeRequest>({
        query: requestData => ({
            url: '/signalr/unsubscribe',
            method: "POST",
            body: requestData
        })
    })
  })
})

export const { 
    useLoginMutation,
    useRefreshTokenMutation,
    useLogoutMutation,

    useAddUserMutation,
    useUpdateUserMutation,
    useGetAllUsersQuery,
    useDeleteUserMutation,

    useAddInstallationMutation,
    useUpdateInstallationMutation,
    useUpdateInstallationLocationMutation,
    useUpdateInstallationDiagramImageMutation,
    useUpdateInstallationDiagramNodesMutation,
    useGetAllInstallationsQuery,
    useDeleteInstallationMutation,

    useAddComponentMutation,
    useUpdateComponentMutation,
    useGetInstallationComponentsQuery,
    useDeleteComponentMutation,

    useGetHistoricDataQuery,
    useLazyGetHistoricDataQuery,
    useGetPredictionDataQuery,
    useLazyGetPredictionDataQuery,
    useLazyGetAllInstallationsQuery,

    useSubscribeMutation,
    useSubscribeAllMutation,
    useUnsubscribeMutation
} = apiSlice
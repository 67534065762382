import { SearchOutlined } from "@mui/icons-material";
import { CircularProgress, IconButton, List, ListItemButton, ListItemSecondaryAction, ListItemText, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { useState } from "react";
import { IInstallation } from "../../models/IInstallation";
import { ReadMore as ReadMoreIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { useAppSelector } from "../../app/hooks";
import { selectCurrentUser } from "../../features/auth/authSlice";
import { UserRole } from "../../models/IUser";

type InstallationsListProps = {
    installations?: IInstallation[],
    selectedInstallation?: IInstallation | null | undefined,
    onSelectedInstallation: (installation: IInstallation) => void,
    onGoToInstallationClick: (installation: IInstallation) => void,
    onDeleteInstallationClick: (installation: IInstallation) => void,
    isLoading: boolean
}

export default function InstallationsList({ installations, selectedInstallation, onSelectedInstallation, onGoToInstallationClick, onDeleteInstallationClick, isLoading }: InstallationsListProps) {
    const authUser = useAppSelector(selectCurrentUser);
    const [filter, setFilter] = useState("");

    const handleDeleteClick = (event: React.MouseEvent<HTMLButtonElement>, installation: IInstallation) => {
        event.stopPropagation();
        onDeleteInstallationClick(installation);
    }

    return (
        isLoading ?
            <Stack height='100%' alignItems="center" justifyContent="center">
                <CircularProgress />
            </Stack>
            :
            <Stack direction="column">
                <TextField
                    variant="outlined"
                    placeholder="Filtro"
                    InputProps={{
                        startAdornment: (
                            <IconButton>
                                <SearchOutlined />
                            </IconButton>
                        ),
                    }}
                    value={filter}
                    onChange={(event) => { setFilter(event.target.value) }}
                    size="small"
                />
                <List sx={{ maxHeight: '100%', overflow: 'auto' }}>
                    {
                        installations?.filter(installation =>
                            installation.name.includes(filter) ||
                            installation.description.includes(filter) ||
                            installation.id.includes(filter)
                        ).map(installation =>
                            <ListItemButton
                                key={installation.id}
                                selected={installation.id === selectedInstallation?.id}
                                onClick={() => onSelectedInstallation(installation)}
                                autoFocus={installation.id === selectedInstallation?.id}
                                disableRipple
                                sx={{
                                    my: "2px",
                                    "&.Mui-selected": {
                                        backgroundColor: "#d6d6d6"
                                    },
                                    "&.Mui-selected:hover": {
                                        backgroundColor: "#d6d6d6"
                                    },
                                    ":hover": {
                                        backgroundColor: "#b6b6b6"
                                    }
                                }}
                            >
                                <ListItemText>{installation.name}</ListItemText>
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="edit" onClick={() => onGoToInstallationClick(installation)} sx={{ mx: '1px' }}>
                                        <ReadMoreIcon />
                                    </IconButton>
                                    {authUser.role !== UserRole.User &&
                                        <IconButton edge="end" aria-label="delete" onClick={(e) => handleDeleteClick(e, installation)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                </ListItemSecondaryAction>
                            </ListItemButton>
                        )
                    }
                </List>
            </Stack>
    )
}
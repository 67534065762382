import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useUpdateInstallationDiagramImageMutation, useUpdateInstallationDiagramNodesMutation } from "../../../features/api/apiSlice";
import useDimensions from "react-cool-dimensions";
import DataInputBox from "./DiagramNodeBox";
import { IDiagramConfig } from "../../../models/IDiagramConfig";
import { IInstallation } from "../../../models/IInstallation";
import AddNodeDialog from "../../Dialogs/Installations/AddNodeDialog";

import { v4 as uuidv4 } from 'uuid';
import { toast } from "react-toastify";
import NodesList from "./NodesList";
import { IDiagramNode } from "../../../models/IDiagramNode";
import GenericDeleteDialog from "../../Dialogs/GenericDeleteDialog";
import EditNodeDialog from "../../Dialogs/Installations/EditNodeDialog";
import { IComponent } from "../../../models/IComponent";
import { Save as SaveIcon, Cancel as CancelIcon } from "@mui/icons-material";

const imageMimeType = /image\/(png|jpg|jpeg|svg)/i;

export type IEditDiagramProps = {
    selectedInstallation: IInstallation;
    components: IComponent[],
    onEditCompleted: () => void;
}

export default function EditDiagramView({ selectedInstallation, components, onEditCompleted }: IEditDiagramProps) {
    const [updateInstallationDiagramImage] = useUpdateInstallationDiagramImageMutation();
    const [updateInstallationDiagramNodes] = useUpdateInstallationDiagramNodesMutation();

    const [file, setFile] = useState(null);
    const [fileDataURL, setFileDataURL] = useState(null);

    const [diagram, setDiagram] = useState<IDiagramConfig>({ imageUrl: null, nodes: null });

    const [selectedNode, setSelectedNode] = useState<IDiagramNode>(null);

    const [openAddNodeDialog, setOpenAddNodeDialog] = useState(false);
    const [openEditNodeDialog, setOpenEditNodeDialog] = useState(false);
    const [openDeleteNodeDialog, setOpenDeleteNodeDialog] = useState(false);

    const handleAddNode = (name: string, expression: string, units: string) => {
        if (diagram.nodes) {
            setDiagram({ ...diagram, nodes: [...diagram.nodes, { id: uuidv4(), name, x: 0, y: 0, expression, units }] })
        } else {
            setDiagram({ ...diagram, nodes: [{ id: uuidv4(), name, x: 0, y: 0, expression, units }] })
        }
    };

    const handleEditNodeClick = (node: IDiagramNode) => {
        setSelectedNode(node);
        setOpenEditNodeDialog(true);
    };

    const handleCloseEditNodeDialog = () => {
        setOpenEditNodeDialog(false);
        setSelectedNode(null);
    };

    const handleEditNode = (node: IDiagramNode) => {
        setDiagram({ ...diagram, nodes: diagram.nodes.map((x) => x.id !== node.id ? x : node) })
    };

    const handleDeleteNodeClick = (node: IDiagramNode) => {
        setSelectedNode(node);
        setOpenDeleteNodeDialog(true);
    };

    const handleCloseDeleteNodeDialog = () => {
        setOpenDeleteNodeDialog(false);
        setSelectedNode(null);
    };

    const handleDeleteNode = (node: IDiagramNode) => {
        setOpenDeleteNodeDialog(false);
        setDiagram({ ...diagram, nodes: diagram.nodes.filter(x => x.id !== node.id) })
        setSelectedNode(null);
    };

    const handleDragStop = (id: string, x: number, y: number) => {
        setDiagram({
            ...diagram,
            nodes: diagram.nodes.map((node) => {
                if (node.id === id) {
                    return { ...node, x: node.x + (x / width * 100), y: node.y + (y / height * 100) };
                } else {
                    return node;
                }
            })
        });
    };

    const handleSave = async () => {
        if (file && fileDataURL) {
            await updateInstallationDiagramImage(
                { installationId: selectedInstallation.id, updateInstallationDiagramImage: { ownerId: selectedInstallation.ownerId, image: file } }
            ).unwrap();
        }

        if (diagram.nodes) {
            updateInstallationDiagramNodes({ installationId: selectedInstallation.id, updateInstallationDiagramNodes: { ownerId: selectedInstallation.ownerId, nodes: diagram.nodes } })
        }

        toast("Cambios guardados!", { type: "success" })
        onEditCompleted();
    }

    const changeImageHandler = (e: any) => {
        const file = e.target.files[0];
        if (!file.type.match(imageMimeType)) {
            alert("Image mime type is not valid");
            return;
        }
        setFile(file);
    }

    useEffect(() => {
        let fileReader: FileReader;
        let isCancel = false;
        if (file) {
            fileReader = new FileReader();
            fileReader.onload = (e) => {
                const { result } = e.target;
                if (result && !isCancel) {
                    setFileDataURL(result)
                }
            }
            fileReader.readAsDataURL(file);
        }

        return () => {
            isCancel = true;
            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort();
            }
        }
    }, [file]);

    useEffect(() => {
        if (selectedInstallation?.diagram) {
            setDiagram(selectedInstallation.diagram)
        }
    }, [selectedInstallation]);

    const { observe, width, height } = useDimensions()

    return (
        <>
            <Stack direction="row" spacing={2} sx={{my: 1}}>
                <Button variant="contained" component="label">
                    Cambiar Imagen
                    <input hidden accept=".png, .jpg, .jpeg, .svg" type="file" onChange={changeImageHandler} />
                </Button>
                <Button variant="contained" onClick={() => setOpenAddNodeDialog(true)}>Añadir Nodo</Button>
                <Button variant="contained" color="error" endIcon={<CancelIcon />} onClick={() => onEditCompleted()}>Cancelar</Button>
                <Button variant="contained" endIcon={<SaveIcon />} onClick={handleSave}>Guardar</Button>
            </Stack>
            <Stack direction="row" spacing={5}>
                <Paper elevation={4} sx={{ width: '70%', height: '100%', minHeight: "80vh", mb: 1 }} square>
                    <Stack direction="column" width='100%' height='100%' padding={2} spacing={1}>
                        {(fileDataURL || diagram?.imageUrl) &&
                            <Box component="div" sx={{ position: "relative" }}>
                                {diagram && diagram.nodes && diagram.nodes.map(node => {
                                    return <DataInputBox
                                        key={node.id}
                                        components={components}
                                        diagramNode={node}
                                        editMode={true}
                                        selected={selectedNode?.id === node.id}
                                        onSelected={(id) => setSelectedNode(diagram.nodes.find(x => x.id === id))}
                                        onDragStop={(x, y) => handleDragStop(node.id, x, y)}
                                    />
                                })}

                                <Box component="img" src={fileDataURL ? fileDataURL : selectedInstallation.diagram.imageUrl} width='100%' height='100%' ref={observe} />
                            </Box>
                        }
                    </Stack>
                </Paper>

                <Paper elevation={4} sx={{ width: '30%', height: '100%', minHeight: '80vh' }} square>
                    <Stack direction="column" width='100%' height='100%' padding={2} spacing={1}>
                        <Typography variant="h5" fontWeight="bold">Nodos</Typography>
                        <NodesList
                            nodes={diagram.nodes}
                            selectedNode={selectedNode}
                            onSelectedNode={(node) => setSelectedNode(node)}
                            onEditNode={handleEditNodeClick}
                            onDeleteNode={handleDeleteNodeClick}
                            isLoading={false}
                        />
                    </Stack>
                </Paper>
            </Stack>

            {openAddNodeDialog && <AddNodeDialog components={components} openDialog={openAddNodeDialog} handleClose={() => setOpenAddNodeDialog(false)} handleAddNode={handleAddNode} />}
            {openEditNodeDialog && <EditNodeDialog components={components} node={selectedNode} openDialog={openEditNodeDialog} handleClose={handleCloseEditNodeDialog} handleEditNode={handleEditNode} />}

            {openDeleteNodeDialog &&
                <GenericDeleteDialog
                    openDialog={openDeleteNodeDialog}
                    handleClose={handleCloseDeleteNodeDialog}
                    title={`Quieres eliminar ${selectedNode.name}?`}
                    message=""
                    handleDelete={() => handleDeleteNode(selectedNode)}
                />
            }
        </>
    );
}
import { Box, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";

export default function Footer(props: any) {
    return (
        <Stack direction="column" alignItems="center" justifyItems="center" {...props}>
            <Typography variant="body2" color="text.secondary" align="center">
                Subvencionado por el CDTI y apoyado por el Ministerio de Ciencia e Innovación
            </Typography>

            <Box component="img" src='footer.png' alt="FOOTER_LOGOS" sx={{ maxWidth: { xs: '80vw' }, maxHeight: 50, display: { md: 'flex' }, mr: 2 , my: 2}} />
        </Stack>
    );
}
import { EditLocationAlt } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import { IInstallation } from "../../models/IInstallation";

type InstallationDetailsProps = {
    installation?: IInstallation | null | undefined,
    onEditLocationTap: () => void
}

export default function InstallationDetails({ installation, onEditLocationTap }: InstallationDetailsProps) {
    if (installation === null || installation === undefined) {
        return (
            <Stack width='100%' height='100%' alignItems="center" justifyContent="center">
                <Typography variant="body1">Selecciona una instalación para ver sus detalles</Typography>
            </Stack>
        )
    } else {
        return (
            <Stack direction="column" width='100%' height='100%' overflow='auto' justifyContent="space-evenly">
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="h6" fontWeight="bold">Identificador:</Typography>
                    <Typography variant="h6">{installation.id}</Typography>
                </Stack>
                
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="h6" fontWeight="bold">Nombre:</Typography>
                    <Typography variant="h6">{installation.name}</Typography>
                </Stack>

                <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="h6" fontWeight="bold">Localización:</Typography>
                    <IconButton onClick={onEditLocationTap}>
                        <EditLocationAlt />
                    </IconButton>
                    <Typography variant="h6">Lat: {installation.latitude} Lon: {installation.longitude}</Typography>
                </Stack>
            </Stack>
        )
    }
}
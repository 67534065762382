import { Backdrop, Box, CircularProgress, Grid, MenuItem, Select, SelectChangeEvent, Stack, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ComponentsTab from "../../components/Installations/Components/ComponentsTab";
import DiagramTab from "../../components/Installations/Diagram/DiagramTab";
import { useGetAllInstallationsQuery, useGetInstallationComponentsQuery } from "../../features/api/apiSlice";
import { selectCurrentUser } from "../../features/auth/authSlice";
import { selectCurrentInstallation, setSelectedInstallation } from "../../features/installations/installationsSlice";
import { UserRole } from "../../models/IUser";

export default function InstallationsPage() {
    const dispatch = useAppDispatch();
    const authUser = useAppSelector(selectCurrentUser);
    const selectedInstallation = useAppSelector(selectCurrentInstallation);

    const { data: installations, isLoading: isLoadingInstallations } = useGetAllInstallationsQuery();

    const { data: components, isLoading: isLoadingComponents } = useGetInstallationComponentsQuery(selectedInstallation?.id);

    const handleInstallationSelected = (event: SelectChangeEvent) => {
        dispatch(setSelectedInstallation(installations.filter(x => x.id === event.target.value as string)[0].id))
    };

    const [selectedTab, setSelectedTab] = useState(0);
    const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    return (
        <Stack direction="column">
            <Grid container display="flex" spacing={1} direction="row" alignItems="center" justifyContent="center" width='100%' sx={{ mt: 2 }}>
                <Grid item xs={12} sx={{ mx: 1 }} >
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Select
                            value={selectedInstallation?.id ?? ""}
                            onChange={handleInstallationSelected}
                            sx={{ minWidth: 120 }}
                            displayEmpty
                            autoWidth
                        >
                            <MenuItem value="">Selecciona una instalación</MenuItem>
                            {installations && installations.map((installation) =>
                                <MenuItem key={installation.id} value={installation.id}>{installation.name}</MenuItem>
                            )}
                        </Select>

                        {selectedInstallation?.id &&
                            <Tabs value={selectedTab} onChange={handleTabChange}>
                                <Tab label="Componentes" tabIndex={0} />
                                <Tab label="Diagrama" tabIndex={1} />
                            </Tabs>
                        }
                    </Stack>
                </Grid>
            </Grid >

            {selectedInstallation?.id && components &&
                <>
                    <Box
                        component="div"
                        role="tabpanel"
                        hidden={selectedTab !== 0}
                        width='100%'
                    >
                        <ComponentsTab
                            selectedInstallationId={selectedInstallation?.id}
                            components={components}
                            isLoading={isLoadingComponents}
                            showActions={authUser.role !== UserRole.User}
                        />
                    </Box>

                    <Box
                        component="div"
                        role="tabpanel"
                        hidden={selectedTab !== 1}
                        width='100%'
                    >
                        <DiagramTab
                            selectedInstallation={selectedInstallation}
                            components={components}
                            showActions={authUser.role !== UserRole.User}
                        />
                    </Box>
                </>
            }

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoadingInstallations}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Stack>
    );
}
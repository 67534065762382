import { Button, CircularProgress, Grid, IconButton, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridColumnHeaderParams, GridRowsProp } from "@mui/x-data-grid";
import { Fragment, useState } from "react";
import { useAddComponentMutation, useDeleteComponentMutation, useUpdateComponentMutation } from "../../../features/api/apiSlice";
import { IComponent } from "../../../models/IComponent";
import { IDataInput } from "../../../models/IDataInput";
import AddComponentDialog from "../../Dialogs/Components/AddComponentDialog";
import UpdateComponentDialog from "../../Dialogs/Components/UpdateComponentDialog";
import GenericDeleteDialog from "../../Dialogs/GenericDeleteDialog";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";

type ComponentsTabProps = {
    selectedInstallationId: string;
    components: IComponent[];
    isLoading: boolean;
    showActions: boolean;
}

export default function ComponentsTab({ selectedInstallationId, components, isLoading, showActions }: ComponentsTabProps) {
    const [selectedComponent, setSelectedComponent] = useState<IComponent | null>(null);

    const [addComponent, addComponentResult] = useAddComponentMutation();
    const [updateComponent, updateComponentResult] = useUpdateComponentMutation();
    const [deleteComponent, deleteComponentResult] = useDeleteComponentMutation();

    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const handleAddComponent = (name: string, dataInputs: IDataInput[], componentId?: string | null) => {
        setOpenAddDialog(false);
        addComponent({ componentId, installationId: selectedInstallationId, name, dataInputs });
    }

    const handleUpdateComponent = (componentId: string, name: string) => {
        setOpenUpdateDialog(false);
        updateComponent({ componentId, updateComponent: { installationId: selectedInstallationId, name } })
    };

    const handleDeleteComponent = (componentId: string) => {
        setOpenDeleteDialog(false);
        deleteComponent({ componentId, deleteComponent: { installationId: selectedInstallationId } })
    };

    const handleUpdateClick = (componentId: string) => {
        setSelectedComponent(components.find((c) => c.id === componentId));
        setOpenUpdateDialog(true);
    };

    const handleCloseUpdateDialog = () => {
        setOpenUpdateDialog(false);
        setSelectedComponent(null);
    };

    const handleDeleteClick = (componentId: string) => {
        setSelectedComponent(components.find((c) => c.id === componentId));
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setSelectedComponent(null);
    };

    const columns: GridColDef[] = [
        {
            field: 'idCol',
            headerName: 'Identificador',
            flex: 1.2,
            renderHeader: (params: GridColumnHeaderParams) => (<strong>{params.colDef.headerName}</strong>)
        },
        {
            field: 'nameCol',
            headerName: 'Nombre',
            flex: 0.8,
            renderHeader: (params: GridColumnHeaderParams) => (<strong>{params.colDef.headerName}</strong>)
        },
        {
            field: 'dataCol',
            headerName: 'Datos',
            flex: 3,
            renderCell: (params) => {
                return (
                    <Grid container columnSpacing={1}>
                        {params.row.dataCol.map((dataInput: IDataInput) => {
                            return (
                                <Fragment key={`${params.row.idCol}:${dataInput.id}`}>
                                    <Grid item xs={2}>
                                        <Stack direction="row" spacing={1}>
                                            <Typography fontWeight="bold">Id:</Typography>
                                            <Typography sx={{wordBreak: "break-all"}}>{dataInput.id}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Stack direction="row" spacing={1}>
                                            <Typography fontWeight="bold">Nombre:</Typography>
                                            <Typography sx={{wordBreak: "break-all"}}>{dataInput.name}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Stack direction="row" spacing={1}>
                                            <Typography fontWeight="bold">Valor:</Typography>
                                            <Typography>{dataInput.value !== null && dataInput.value !== undefined && !isNaN(dataInput.value) && isFinite(dataInput.value) ? `${dataInput.value.toFixed(2)} ${dataInput.units}` : ""} {dataInput.date ? `(${new Date(dataInput.date).toLocaleString()})   ` : ""}</Typography>
                                        </Stack>
                                    </Grid>
                                </Fragment>
                            )
                        })
                        }
                    </Grid>
                )
            },
            renderHeader: (params: GridColumnHeaderParams) => (<strong>{params.colDef.headerName}</strong>)
        },
        showActions && {
            field: 'actionsCol',
            headerName: 'Acciones',
            width: 100,
            renderCell: (params) => {
                return (
                    <Stack direction="row">
                        <IconButton onClick={() => handleUpdateClick(params.row.idCol)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteClick(params.row.idCol)}>
                            <DeleteIcon />
                        </IconButton>
                    </Stack>
                )
            },
            renderHeader: (params: GridColumnHeaderParams) => (<strong>{params.colDef.headerName}</strong>)
        }
    ];

    const rows: GridRowsProp = components ?
        components.map((component) => {
            return {
                id: component.id,
                idCol: component.id,
                nameCol: component.name,
                dataCol: component.dataInputs ?? "SIN DATOS"
            }
        })
        :
        [];

    return (
        <Grid container display="flex" direction="row" alignItems="center" justifyContent="center" width='100%' rowSpacing={1}>
            {(isLoading || addComponentResult.isLoading || updateComponentResult.isLoading || deleteComponentResult.isLoading) &&
                <Grid item display="flex" xs={12} minHeight="70vh" alignItems="center" justifyContent="center">
                    <CircularProgress />
                </Grid>
            }

            <Grid item display="flex" justifyContent="end" xs={12} sx={{ mr: 1 }}>
                {showActions &&
                    <Button variant="contained" onClick={() => setOpenAddDialog(true)}>Añadir Componente</Button>
                }
            </Grid>

            {components?.length === 0 ?
                <Grid item display="flex" xs={12} minHeight="70vh" alignItems="center" justifyContent="center">
                    <Typography variant="h6">
                        Esta instalación no tiene componentes
                    </Typography>
                </Grid>
                :
                <Grid item xs={12} sx={{ mx: 1 }}>
                    <DataGrid
                        autoHeight
                        getRowHeight={() => 'auto'}
                        columns={columns}
                        rows={rows}
                        disableColumnMenu
                    />
                </Grid>
            }

            {openAddDialog &&
                <AddComponentDialog
                    openDialog={openAddDialog}
                    handleClose={() => setOpenAddDialog(false)}
                    handleAddComponent={handleAddComponent}
                />
            }
            {openUpdateDialog && <UpdateComponentDialog openDialog={openUpdateDialog} handleClose={handleCloseUpdateDialog} component={selectedComponent} handleUpdateComponent={handleUpdateComponent} />}

            {openDeleteDialog &&
                <GenericDeleteDialog
                    openDialog={openDeleteDialog}
                    handleClose={handleCloseDeleteDialog}
                    title={`Quieres eliminar el componente ${selectedComponent.name} con Identificador ${selectedComponent.id} ?`}
                    message="Esta acción no es reversible. Se perderán todos los datos históricos y predicciones asociados a este componente."
                    handleDelete={() => handleDeleteComponent(selectedComponent.id)}
                />
            }
        </Grid>
    )
}
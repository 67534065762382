import { Box, Stack } from "@mui/material";
import { IDiagramConfig } from "../../../models/IDiagramConfig";
import DataInputBox from "../../../components/Installations/Diagram/DiagramNodeBox";
import { IDiagramNode } from "../../../models/IDiagramNode";
import { IComponent } from "../../../models/IComponent";

export type IDiagramPanelProps = {
    components: IComponent[];
    diagram?: IDiagramConfig;
    selectedNodes?: IDiagramNode[];
    onSelectedNode: (node: IDiagramNode) => void;
};

export default function DiagramPanel({ components, diagram, selectedNodes, onSelectedNode }: IDiagramPanelProps) {
    return (diagram?.imageUrl ?
        <Stack direction="column" width='100%' padding={2} spacing={1} sx={{ minHeight: '100%' }}>
            <Box component="div" sx={{ position: "relative" }}>
                {diagram?.nodes && diagram.nodes.map(node => {
                    return <DataInputBox
                        key={node.id}
                        components={components}
                        diagramNode={node}
                        editMode={false}
                        selected={selectedNodes.find(x => x.id === node.id) !== undefined}
                        onSelected={(id) => onSelectedNode(diagram.nodes.find(x => x.id === id))}
                    />
                })}

                <Box component="img" src={diagram.imageUrl} width='100%' height='100%' />
            </Box>
        </Stack>
        :
        null
    )
}